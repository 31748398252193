<template>
  <div class="basic-filter">
    <CRow class="mt-3">
      <CCol md="4">
        <CRow class="row pb-3">
          <RadioButton
            class="mt-2 ml-2"
            name="job_groups_id"
            :value="job_groups_radio.job_groups_id"
            :options="(options && options['job_groups_id']) || []"
            @change="handleChangeRadio"
          />
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              v-if="job_groups_radio.job_groups_id === 1"
              name="job"
              :value="filters.job"
              @input="handleChangeMultiSelectAsArray"
              :options="(options && options['job']) || []"
              :taggable="false"
              :multiple="true"
              :clearable="false"
            />
            <Select
              v-else
              name="job_groups"
              :value="filters.job_groups"
              @input="handleChangeMultiSelectAsArray"
              :options="(options && options['job_groups']) || []"
              :taggable="false"
              :multiple="true"
              :clearable="false"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="row mb-3">
          <label class="col-lg-12 col-md-12 mt-2">Status</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="tab"
              :value="filters.tab"
              @input="handleChangeSelect"
              :options="(options && options['tab']) || []"
              :taggable="false"
              :multiple="false"
              :clearable="true"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="row mb-3">
          <label class="col-lg-12 col-md-12 mt-2">Sub Status</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="tabFilter"
              :value="filters.tabFilter"
              @input="handleChangeSelect"
              :options="(options && options['tabFilter']) || []"
              :taggable="false"
              :multiple="false"
              :clearable="false"
              :disabled="
                filtersCode.tab && filtersCode.tab != 'allTab' ? false : true
              "
            />
          </div>
        </CRow>
      </CCol>
    </CRow>
    <CRow>
      <CCol
        md="4"
        v-if="filtersCode.tabFilter && options['tabSubFilter'].length"
      >
        <CRow class="row mb-3">
          <label class="col-lg-12 col-md-12">Additional Filters</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="tabSubFilter"
              :value="filters.tabSubFilter"
              @input="handleChangeSelect"
              :options="(options && options['tabSubFilter']) || []"
              :taggable="false"
              :multiple="false"
              :clearable="true"
              :disabled="filtersCode.tabFilter ? false : true"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4" v-if="filtersCode.tab == 1">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Match Type</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="reference_type_id"
              :value="filters.reference_type_id"
              @input="handleChangeSelect"
              :options="(options && options['reference_type_id']) || []"
              :taggable="false"
              :multiple="false"
              :clearable="true"
            />
          </div>
        </CRow>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="4">
        <div class="d-flex">
          <CButton
            class="btn-primary rounded-0 small"
            @click="
              $router.push('/job-candidates-status?tabIndex=0&tab=allTab')
            "
            >Clear All</CButton
          >
          <h5 class="mt-1 ml-3">
            Search Results: {{ getJobCandidatesByStatusIDCount }}
          </h5>
        </div>
      </CCol>
      <CCol md="8">
        <Pagination
          :total="getJobCandidatesByStatusIDCount"
          :perPage="candidatesPerPage"
          @pagechanged="pagechanged"
          :maxVisibleButtons="isMobile ? 3 : 5"
          :position="isMobile ? 'left' : 'end'"
        />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  getFilterQueryStringWithoutArray,
  deepClone,
} from "@/helpers/helper.js";
import Select from "@/components/reusable/Fields/Select";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import Pagination from "@/components/reusable/Pagination";
export default {
  name: "BasicFilter",
  props: {},
  components: {
    Select,
    Pagination,
    RadioButton,
  },
  data() {
    return {
      filters: {},
      job_groups_radio: {
        job_groups_id: 1,
      },
      filtersCode: {},
      isMobile: false,
      candidatesPerPage: 10,
    };
  },
  computed: {
    ...mapGetters([
      "getAllJobsFilterList",
      "getJobCandidateTabs",
      "getTabFilterOptions",
      "getTabSubFilterOptions",
      "getJobCandidatesByStatusIDCount",
      "getJobCandidatesByStatusID",
      "getCustomerId",
      "getIsChildUser",
      "getOrgIDFromAccessToken",
      "isHeadlessClient",
      "getJobGroups",
    ]),
    options() {
      return {
        job: [
          {
            label: "All Jobs",
            code: "all",
          },
          ...this.getAllJobsFilterList,
        ],
        job_groups: [
          { label: "All Groups", code: "all" },
          ...this.getJobGroups?.map((val) => ({
            label: val.group_name,
            code: val.group_id,
          })),
        ],
        job_groups_id: [
          { code: 1, label: "Jobs" },
          { code: 2, label: "Groups" },
        ],
        tab: [
          { label: "All", code: "allTab" },
          ...this.getJobCandidateTabs?.map((val) => ({
            label: val.tab_name,
            code: val.tab_id,
          })),
          { label: "Applied/Rejected", code: "appliedRejected" },
        ],
        tabFilter: this.customizedTabFilter,
        tabSubFilter: this.getTabSubFilterOptions,
        reference_type_id: [
          { code: 1, label: "Exact Match" },
          { code: 3, label: "Similar Match" },
          { code: 4, label: "Potential Match" },
        ],
      };
    },
    customizedTabFilter() {
      let tabFilter = this.getTabFilterOptions;
      if (this.filters?.tab?.code === "appliedRejected") {
        tabFilter = [
          { label: "All", code: "all" },
          { label: "Matched", code: "matched" },
          { label: "Rejected", code: "rejected" },
        ];
      }
      return tabFilter;
    },
    CustomerOrganisation() {
      if (this.getCustomerId && this.getIsChildUser && !this.isHeadlessClient) {
        return {
          jobs___customer_uid: this.getCustomerId,
          jobs___organisation_id: this.getOrgIDFromAccessToken,
        };
      }
      return {
        jobs___customer_uid: this.getCustomerId,
      };
    },
  },
  methods: {
    ...mapActions([
      "fetchJobCandidatesByStatusID",
      "fetchJobTabFilterOptions",
      "fetchJobTabSubFilterOptions",
      "fetchDisplayStatusSubStatus",
      "fetchJobApplications",
      "fetchJobGroups",
    ]),
    handleChangeRadio(name, value) {
      let val = value.code || value.id;
      Vue.set(this.job_groups_radio, name, val);
      delete this.filtersCode['job'];
      delete this.filtersCode['job_groups'];
      this.clearAndUpdateDependentFilters(this.filtersCode, name);
    },

    handleChangeMultiSelectAsArray(name, value) {
      if (["job", "job_groups"].includes(name)) {
        let last_index = value[value.length - 1];
        if (last_index?.code === "all" || !last_index) {
          if (name == "job") value = [{ code: "all", label: "All Jobs" }];
          else value = [{ code: "all", label: "All Groups" }];
        } else {
          value = value.filter((a) => {
            if (a.code != "all") {
              return a;
            }
          });
        }
      }
      Vue.set(this.filters, name, value);
      let code = value
        ? value.map((e) => {
            return e.code;
          })
        : null;
      let newChange = deepClone(this.filtersCode);
      Vue.set(newChange, name, code);
      this.clearAndUpdateDependentFilters(newChange, name);
    },
    handleChangeSelect(name, value) {
      if (name == "tabFilter" && value == null) return;
      Vue.set(this.filters, name, value);
      let code = value ? value.id || value.code || value : null;
      let newChange = deepClone(this.filtersCode);
      Vue.set(newChange, name, code);
      this.clearAndUpdateDependentFilters(newChange, name);
    },
    clearAndUpdateDependentFilters(newChange, name) {
      if (name == "tab") {
        newChange.tabFilter = null;
        // Setting tabFilter to all if tab is not allTab or appliedRejected
        if (newChange.tab) {
          if (["appliedRejected"].includes(newChange.tab))
            newChange.tabFilter = "all";
          else if (!["allTab"].includes(newChange.tab)) newChange.tabFilter = 2;
        }
        newChange.tabSubFilter = null;
      }
      if (name == "tabFilter") {
        newChange.tabSubFilter = null;
      }
      if (name != "reference_type_id") newChange.reference_type_id = null;
      this.updateUrl(newChange);
    },
    updateUrl(newChange) {
      const query = { tabIndex: 0, ...newChange };
      if (query?.page) delete query.page;
      for (const [key, value] of Object.entries(query)) {
        if ((["job", "job_groups"].includes(key) && value == "all") || !value) {
          delete query[key];
        }
      }
      this.$router.push(
        `/job-candidates-status?${getFilterQueryStringWithoutArray(query)}`
      );
    },
    getSkipLimitByPage(pageNumber) {
      const skip = (pageNumber - 1) * this.candidatesPerPage || 0;
      const limit = this.candidatesPerPage;
      return { skip, limit };
    },
    pagechanged(pageNumber) {
      const { skip, limit } = this.getSkipLimitByPage(pageNumber);
      let query = this.$route.query || {};
      if (query?.page) delete query.page;
      this.$router.push(
        `/job-candidates-status?page=${pageNumber}&${getFilterQueryStringWithoutArray(
          query
        )}`
      );
      let data = {
        pagination: false,
        skip,
        limit,
      };
      if (this.filters?.reference_type_id?.code) {
        data = {
          ...data,
          reference_type_id: this.filters?.reference_type_id?.code,
        };
      }
      let appendAction = [];
      if (this.filters?.tab?.code === "appliedRejected") {
        // For Matched tab
        if (this.filters?.tabFilter?.code === "matched") {
          data.is_accepted__in = "null,true";
        }
        // For Rejected tab
        if (this.filters?.tabFilter?.code === "rejected") {
          data.is_accepted__in = "false";
        }
        appendAction = [
          this.fetchJobApplications({
            ...data,
            ...this.CustomerOrganisation,
          }),
        ];
      } else {
        appendAction = [this.fetchJobCandidatesByStatusID(data)];
      }
      Promise.all(appendAction).then((res) => {
        this.$emit("setJobCandidates", this.getJobCandidatesByStatusID);
      });
    },
    fetchFilterOptions(name) {
      if (
        (name == "tab" &&
          ["allTab", "appliedRejected"].includes(this.filtersCode?.tab)) ||
        (name == "tabFilter" &&
          ["all", "matched", "rejected"].includes(this.filtersCode?.tabFilter))
      )
        return;
      switch (name) {
        case "tab":
          return this.fetchJobTabFilterOptions({
            tab_id: this.filtersCode?.tab,
          });
        case "tabFilter":
          return this.fetchJobTabSubFilterOptions({
            tab_id: this.filtersCode?.tab,
            filter_id: this.filtersCode?.tabFilter,
          });
        default:
          break;
      }
    },
    async fetchCandidatesAction(payload) {
      await this.fetchCandidates(payload);
    },
    async fetchCandidates(payload = {}) {
      const { query } = payload;
      let appendAction = [];
      let tempFiltersCode = deepClone(this.filtersCode);
      this.filtersCode = {};
      for (const [key, value] of Object.entries(query)) {
        this.filtersCode[key] = value;
        if (tempFiltersCode[key] == value) continue;
        appendAction.push(this.fetchFilterOptions(key));
      }
      await Promise.all(appendAction).then((response) => {
        let filters = {
          job: { label: "All Jobs", code: "all" },
          job_groups: { label: "All Groups", code: "all" },
        };
        for (const [key, value] of Object.entries(query)) {
          if (this.options[key]) {
            if (["job", "job_groups"].includes(key) && value) {
              const ids = value?.split(",").map((v) => parseInt(v));
              filters[key] =
                this.options[key].filter((v) => ids.includes(v.code)) || [];
            } else {
              filters[key] = this.options[key].filter(
                (val) => value == val.code
              )[0];
            }
          }
        }
        this.filters = { ...filters };
      });
      let data = {
        pagination: false,
        skip: payload?.skip,
        limit: payload?.limit,
      };
      // job_groups
      if (
        this.filters?.job_groups?.length &&
        this.filters?.job_groups[0]?.code !== "all"
      ) {
        data.jobs___group_ids__overlap = `{${this.filters?.job_groups
          ?.map((v) => v.code)
          ?.join(",")}}`;
      }
      if (this.filters?.tab?.code === "appliedRejected") {
        // For Matched tab
        if (this.filters?.tabFilter?.code === "matched") {
          data.is_accepted__in = "null,true";
        }
        // For Rejected tab
        if (this.filters?.tabFilter?.code === "rejected") {
          data.is_accepted__in = "false";
        }
        await this.fetchJobApplications({
          ...data,
          ...this.CustomerOrganisation,
        }).then((res) => {
          this.$emit("setJobCandidates", this.getJobCandidatesByStatusID);
        });
      } else {
        await this.fetchDisplayStatusSubStatus().then(async (finalResponse) => {
          if (this.filters?.reference_type_id?.code) {
            data = {
              ...data,
              reference_type_id: this.filters?.reference_type_id?.code,
            };
          }
          await this.fetchJobCandidatesByStatusID(data).then((res) => {
            this.$emit("setJobCandidates", this.getJobCandidatesByStatusID);
          });
        });
      }
    },
  },
  mounted() {
    const pageNo = parseInt(this.$router.currentRoute?.query?.page || 1);
    const { skip, limit } = this.getSkipLimitByPage(pageNo);
    const query = this.$router.currentRoute?.query;
    if (query?.job_groups) {
      this.job_groups_radio.job_groups_id = 2;
    }
    this.fetchJobGroups().then(() => {
      this.fetchCandidatesAction({ skip, limit, query });
    });
  },
  watch: {
    "$route.query": function (newVal, oldVal) {
      const newQuery = _.reduce(
        newVal,
        function (result, value, key) {
          return _.isEqual(value, oldVal[key])
            ? result
            : value
            ? result.concat(key)
            : result;
        },
        []
      ).filter((val) => val);
      if (newQuery.length === 1 && newQuery.includes("page")) return;
      this.fetchCandidatesAction({ query: newVal });
    },
  },
};
</script>
