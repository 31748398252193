<template>
  <div class="advanced-filter">
    <CRow class="mt-3">
      <CCol md="4">
        <CRow class="row pb-3">
          <label class="col-lg-12 col-md-12">Jobs</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="job_id"
              :value="filters.job_id"
              @input="handleChangeSelectFilter"
              :options="options && options['jobs'] ? options['jobs'] : []"
              placeholder="All"
              :taggable="false"
              :multiple="false"
              :clearable="true"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="row mb-3">
          <label class="col-lg-12 col-md-12">Status</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="tab"
              :value="filters.tab"
              @input="handleChangeSelectFilter"
              :options="options && options['tab'] ? options['tab'] : []"
              :taggable="false"
              :multiple="false"
              :clearable="false"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="row mb-3">
          <label class="col-lg-12 col-md-12">Sub Status</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="tabFilter"
              :value="filters.tabFilter"
              @input="handleChangeSelectFilter"
              :options="
                options && options['tabFilter'] ? options['tabFilter'] : []
              "
              :taggable="false"
              :multiple="false"
              :clearable="false"
            />
          </div>
        </CRow>
      </CCol>
    </CRow>
    <div class="mb-3" v-if="isParticularJob">
      <CButton
        colour="primary"
        class="btn-link px-0"
        @click="isAdvancedFilter = !isAdvancedFilter"
        >{{ isAdvancedFilter ? "Hide" : "Show" }} Advanced Filter</CButton
      >
      <CCollapse :show="isAdvancedFilter">
        <CTabs class="mb-2">
          <CTab title="Pre Screening">
            <CListGroup>
              <div
                v-for="(question, index) in preScreeningQns"
                :key="question.question_id"
              >
                <CListGroupItem
                  class="py-2 rounded-0"
                  href="javascript:"
                  @click="showCollapse(question.question_id)"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="m-0">{{ question.question }}</p>
                    <button class="btn shadow-none">
                      <i
                        :class="`fa fa-${
                          collapseIndex === question.question_id
                            ? 'minus'
                            : 'plus'
                        }`"
                      ></i>
                    </button>
                  </div>
                </CListGroupItem>
                <CCollapse :show="collapseIndex === question.question_id">
                  <CCard class="mb-0">
                    <CCardBody>
                      <CCol md="6" class="px-0">
                        <Select
                          v-if="[6, 7].includes(question.question_type_id)"
                          :name="`options_response_${index}`"
                          :value="question.answer.options_response"
                          :options="question.options || []"
                          @change="handleChangeSelect"
                          :clearable="true"
                          :multiple="question.question_type_id === 7"
                        />
                        <RadioButton
                          v-if="question.question_type_id === 1"
                          :name="`yes_no_response_${index}`"
                          :value="question.answer.yes_no_response"
                          :options="
                            options && options['boolean']
                              ? options['boolean']
                              : []
                          "
                          @change="handleRadio"
                        />
                        <TextareaInput
                          v-if="question.question_type_id === 2"
                          :name="`text_area_response_${index}`"
                          :value="question.answer.text_area_response"
                          @input="handleInput"
                        />
                        <TextInput
                          v-if="question.question_type_id === 3"
                          :name="`numeric_response_${index}`"
                          type="number"
                          :value="question.answer.numeric_response"
                          @input="handleInput"
                        />
                        <Rating
                          v-if="question.question_type_id === 5"
                          :name="`rating_count_${index}`"
                          :isNumberRating="true"
                          :maxRating="question.max_rating_count"
                          :value="question.answer.rating_count"
                          @change="handleChangeRating"
                        />
                        <DatePicker
                          v-if="question.question_type_id === 4"
                          :name="`date_response_${index}`"
                          format="DD-MM-YYYY"
                          :value="question.answer.date_response"
                          @input="handleDatePickerChange"
                        />
                      </CCol>
                    </CCardBody>
                  </CCard>
                </CCollapse>
              </div>
              <p
                class="m-2 text-muted text-center"
                v-if="!preScreeningQns.length"
              >
                No Pre Screening Questions Found
              </p>
            </CListGroup>
          </CTab>
          <CTab title="Candidate Filter">
            <div class="mt-2">
              <CRow>
                <CCol md="4">
                  <CRow>
                    <label class="col-md-12 col-lg-12">
                      Speciality/Sub-Speciality
                    </label>
                    <CCol md="12">
                      <AccordionSelect
                        name="candidates___speciality_references"
                        :label="''"
                        :selectedValue="[]"
                        :value="
                          candidateFilter.candidates___speciality_references
                        "
                        @input="handleAccordionSelect"
                        :onInput="handleAccordionSelect"
                        :options="
                          options && options['speciality']
                            ? options['speciality']
                            : []
                        "
                        :accordionFetchOption="accordionFetchOption"
                        :specialitySmartOptions="specialitySmartOptions"
                        :enableNA="true"
                        :disabled="
                          !filterPayload.candidates___candidate_type_id__in
                        "
                        valueConsistsOf="SECOND_LEAF_PRIORITY"
                        placeholder="Start typing"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="4">
                  <CRow>
                    <label class="col-md-12 col-lg-12"> Gender </label>
                    <CCol md="12">
                      <Select
                        name="candidates___gender_id"
                        :options="
                          options && options['gender'] ? options['gender'] : []
                        "
                        :value="filters.candidates___gender_id"
                        @change="handleChangeSelectFilter"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <!-- <CCol md="4">
                  <CRow>
                    <label class="col-md-12 col-lg-12"> Active License </label>
                    <CCol md="12">
                      <Select
                        name="candidates___current_license"
                        :value="filters.candidates___current_license"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeSelectFilter"
                      />
                    </CCol>
                  </CRow>
                </CCol> -->
              </CRow>
            </div>
          </CTab>
        </CTabs>
      </CCollapse>
      <SelectedTags
        :appliedFilters="appliedFilters"
        @removeFilter="removeFilter"
      />
    </div>
    <CRow>
      <CCol md="4">
        <div class="d-flex">
          <CButton class="btn-primary rounded-0 small" @click="clearFiler"
            >Clear All</CButton
          >
          <h5 class="mt-1 ml-3">
            Search Results: {{ getJobCandidatesAdvancedCount }}
          </h5>
        </div>
      </CCol>
      <CCol md="8">
        <Pagination
          :total="getJobCandidatesAdvancedCount"
          :perPage="candidatesPerPage"
          @pagechanged="pagechanged"
          :maxVisibleButtons="isMobile ? 3 : 5"
          :position="isMobile ? 'left' : 'end'"
        />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  getFilterQueryStringWithoutArray,
  isEmptyObjectCheck,
  isObject,
  convertOptionsToTreeOptions,
} from "@/helpers/helper.js";
import Pagination from "@/components/reusable/Pagination";
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import Rating from "@/components/reusable/Fields/Rating";
import SelectedTags from "@/pages/JobCandidatesStatus/SelectedTags";
import AccordionSelect from "@/components/reusable/Fields/accordionSelect";
import m from "moment";
import _ from "lodash";
export default {
  name: "AdvancedFilter",
  props: {},
  components: {
    Select,
    Pagination,
    TextInput,
    TextareaInput,
    DatePicker,
    RadioButton,
    Rating,
    SelectedTags,
    AccordionSelect,
  },
  data() {
    return {
      filters: {},
      filterPayload: { tabIndex: 1 },
      isAdvancedFilter: false,
      isMobile: false,
      collapseIndex: null,
      candidatesPerPage: 10,
      preScreeningQns: [],
      pre_screening_questions_payload: [],
      selectedFilter: [],
      specialitySmartOptions: [],
      candidateFilter: {},
    };
  },
  computed: {
    ...mapGetters([
      "getAllJobsFilterList",
      "preScreeningQuestions",
      "getJobCandidateTabs",
      "getTabFilterOptions",
      "getJobCandidatesAdvancedCount",
      "getJobCandidatesAdvanced",
      "gender",
      "candidateType",
      "specialitySubSpeciality",
      "genericSpecialitySubSpeciality",
    ]),
    options() {
      return {
        jobs: this.getAllJobsFilterList,
        tab: this.inProcessOpt,
        tabFilter: this.appliedOpt,
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        gender: this.gender || [],
        candidate_type: this.candidateType || [],
      };
    },
    inProcessOpt() {
      return (
        this.getJobCandidateTabs
          ?.filter(({ tab_id }) => tab_id === 1)
          .map((val) => ({
            label: val.tab_name,
            code: val.tab_id,
          })) || []
      );
    },
    appliedOpt() {
      return this.getTabFilterOptions.filter(({ code }) => code === 1) || [];
    },
    isParticularJob() {
      return this.filters?.job_id?.code || false;
    },
    appliedFilters() {
      const appliedFilter =
        this.preScreeningQns
          .filter(
            (v) =>
              v?.answer && isObject(v?.answer) && !isEmptyObjectCheck(v?.answer)
          )
          .filter((v) => {
            const k = Object.keys(v?.answer)[0];
            if (v?.answer[k] && Array.isArray(v?.answer[k])) {
              return v?.answer[k].length > 0;
            }
            return v?.answer[k] != null;
          })
          .map((v) => ({
            question_id: v?.question_id,
            question: v?.question,
            answer: Object.keys(v?.answer)
              .map((k) => {
                if (k === "date_response")
                  return m(v?.answer[k]).format("DD-MM-YYYY");
                // else if(k === "options_response")
                //   return v?.answer[k].filter(e=>e);
                return v?.answer[k];
              })
              .reduce((a, b) => {
                if (b) return a.concat(b);
              }, []),
          })) || [];
      return appliedFilter;
    },
  },
  methods: {
    ...mapActions([
      "getPreScreeningQuestions",
      "fetchJobTabFilterOptions",
      "fetchDisplayStatusSubStatus",
      "fetchJobCandidatesAdvanced",
      "initFetchAdvFilter",
      "genericSpecialitySearch",
      "getGenericSpecialitySubSpeciality",
    ]),
    async accordionFetchOption(search, loading, propertyName, cb) {
      const candidate_type_id =
        this.filterPayload?.candidates___candidate_type_id__in;
      let dynamicQueryString = `query=${encodeURIComponent(
        search
      )}&candidate_type_id=${candidate_type_id}`;
      if (search.length < 3) {
        cb(null, []);
      } else {
        await this.genericSpecialitySearch(dynamicQueryString).then(
          async (res) => {
            const options = await convertOptionsToTreeOptions(
              this.specialitySubSpeciality
            );
            this.specialitySmartOptions = options;
            cb(null, options);
          }
        );
      }
    },
    handleAccordionSelect(name, value) {
      this.filterPayload[name] = value;
      this.getGenericSpecialitySubSpeciality(this.filterPayload[name]);
      Vue.set(this.filters, name, this.genericSpecialitySubSpeciality);
      this.fetchCandidates();
    },
    showCollapse(index) {
      if (this.collapseIndex === index) {
        this.collapseIndex = null;
        return;
      }
      this.collapseIndex = index;
    },
    async handleChangeSelectFilter(name, value) {
      Vue.set(this.filters, name, value);
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      this.filterPayload = {
        ...this.filterPayload,
        [name]: val,
      };
      this.clearDepentendFilter(name);
      if (name === "job_id" && value?.candidate_type_id)
        this.handleChangeSelectFilter(
          "candidates___candidate_type_id__in",
          value?.candidate_type_id
        );
      if (name === "tab") {
        await this.fetchJobTabFilterOptions({
          tab_id: this.filterPayload?.tab,
        });
      } else if (name === "job_id") this.updateUrl();
      else if (
        [
          "candidates___candidate_type_id__in",
          "candidates___gender_id",
          "candidates___current_license",
        ].includes(name)
      )
        this.fetchCandidates();
      return;
    },
    clearDepentendFilter(changedFilter) {
      const dependentFilter = {
        job_id: [
          "candidates___candidate_type_id__in",
          "candidates___speciality_references",
          "candidates___gender_id",
        ],
      };
      dependentFilter[changedFilter]?.forEach((element) => {
        if (this.filterPayload[element]) this.filterPayload[element] = null;
      });
    },
    updateUrl() {
      const payload = _.chain(this.filterPayload)
        .omitBy(_.isNil)
        .omit("candidates___candidate_type_id__in")
        .pickBy((value) => !(_.isArray(value) && _.isEmpty(value)))
        .value();
      payload?.candidates___speciality_references?.length;
      this.$router.push(
        `/job-candidates-status?${getFilterQueryStringWithoutArray(payload)}`
      );
    },
    handleChangeSelect(_name, value) {
      let val = value || [];
      if (value && !Array.isArray(value)) val = [value];
      const { name, index } = this.decodeName(_name);
      if (val.length) Vue.set(this.preScreeningQns[index].answer, name, val);
      else Vue.delete(this.preScreeningQns[index].answer, name);
      this.applyAdvancedFilter(this.preScreeningQns[index]?.question_id);
    },
    handleRadio(_name, value) {
      const { name, index } = this.decodeName(_name);
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.preScreeningQns[index].answer, name, val);
      this.applyAdvancedFilter(this.preScreeningQns[index]?.question_id);
    },
    handleInput(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.preScreeningQns[index].answer, name, value);
      this.applyAdvancedFilter(this.preScreeningQns[index]?.question_id);
    },
    handleChangeRating(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.preScreeningQns[index].answer, name, value);
      this.applyAdvancedFilter(this.preScreeningQns[index]?.question_id);
    },
    handleDatePickerChange(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.preScreeningQns[index].answer, name, value);
      this.applyAdvancedFilter(this.preScreeningQns[index]?.question_id);
    },
    decodeName(data) {
      if (data) {
        const split_index = data.lastIndexOf("_");
        const name = data.substr(0, split_index);
        const index = data.substr(split_index + 1) || null;
        return { name, index };
      }
      return data;
    },
    getSkipLimitBypage(pageNumber = 1) {
      const skip = (pageNumber - 1) * this.candidatesPerPage || 0;
      const limit = this.candidatesPerPage;
      return { skip, limit };
    },
    pagechanged(pageNumber) {
      const { skip, limit } = this.getSkipLimitBypage(pageNumber);
      let query = this.$route.query || {};
      query.page ? delete query.page : null;
      this.$router.push(
        `/job-candidates-status?page=${pageNumber}&${getFilterQueryStringWithoutArray(
          query
        )}`
      );
      const finalPayload = {
        ...this.filterPayload,
        skip,
        limit,
        pagination: true,
        payload: {
          pre_screening_questions: this.pre_screening_questions_payload,
        },
      };
      this.fetchJobCandidatesAdvanced(finalPayload).then((res) => {
        this.$emit("setJobCandidates", this.getJobCandidatesAdvanced);
      });
    },
    fetchCandidates() {
      this.pre_screening_questions_payload = [];
      const { skip, limit } = this.getSkipLimitBypage();
      this.fetchDisplayStatusSubStatus().then((res) => {
        const finalPayload = {
          ...this.filterPayload,
          skip,
          limit,
          pagination: false,
        };
        this.fetchJobCandidatesAdvanced(finalPayload).then((res) => {
          this.$emit("setJobCandidates", this.getJobCandidatesAdvanced);
        });
      });
    },
    fetchAdvancedFilters() {
      if (this.filterPayload?.job_id)
        this.getPreScreeningQuestions(this.filterPayload?.job_id).then(
          (res) => {
            if (res.status === 200) {
              this.pre_screening_questions_payload = [];
              this.preScreeningQns = this.preScreeningQuestions.map((v) => {
                return {
                  question_type_id: v?.question_type?.question_type_id,
                  question: v?.question,
                  question_id: v?.question_id,
                  is_mandatory: v?.is_mandatory,
                  options: v?.options,
                  max_rating_count: v?.max_rating_count,
                  answer: {},
                };
              });
            }
          }
        );
    },
    async populateFilter() {
      let query = this.$router.currentRoute.query;
      if (query?.tab) {
        const tab =
          this.options?.tab.filter((v) => v?.code == query?.tab) || [];
        await this.handleChangeSelectFilter("tab", tab.length ? tab[0] : null);
      }
      if (query?.tabFilter) {
        const tabFilter =
          this.options?.tabFilter.filter((v) => v?.code == query?.tabFilter) ||
          [];
        this.handleChangeSelectFilter(
          "tabFilter",
          tabFilter.length ? tabFilter[0] : null
        );
      }
      const job_ids =
        this.options?.jobs.filter((v) => v?.code == query?.job_id) || [];
      this.handleChangeSelectFilter(
        "job_id",
        job_ids.length ? job_ids[0] : null
      );
      if (job_ids.length) {
        this.fetchAdvancedFilters();
      }
      this.fetchCandidates();
    },
    applyAdvancedFilter(question_id) {
      const answerPrescreeningQns = this.preScreeningQns.filter(
        (v) =>
          v?.answer && isObject(v?.answer) && !isEmptyObjectCheck(v?.answer)
      );
      this.pre_screening_questions_payload = answerPrescreeningQns.map((v) => ({
        question_id: v?.question_id,
        ...v?.answer,
      }));
      const { skip, limit } = this.getSkipLimitBypage();
      const finalPayload = {
        ...this.filterPayload,
        skip,
        limit,
        pagination: false,
        payload: {
          pre_screening_questions: this.pre_screening_questions_payload,
        },
      };
      this.fetchJobCandidatesAdvanced(finalPayload).then((res) => {
        this.$emit("setJobCandidates", this.getJobCandidatesAdvanced);
      });
    },
    clearFiler() {
      this.handleChangeSelectFilter("job_id", null);
      this.preScreeningQns = [];
    },
    removeFilter(question_id, index) {
      const qnsIndex = this.preScreeningQns.findIndex(
        (val) => val?.question_id === question_id
      );
      const { answer } = this.preScreeningQns[qnsIndex];
      if (answer) {
        const key = Object.keys(answer)[0];
        const val = this.preScreeningQns[qnsIndex].answer[key];
        if (val && Array.isArray(val)) {
          const newVal = val.splice(index, 1);
          Vue.set(this.preScreeningQns[qnsIndex], answer, newVal);
        } else {
          Vue.set(this.preScreeningQns[qnsIndex], "answer", {});
        }
      }
      this.applyAdvancedFilter();
    },
  },
  mounted() {
    this.populateFilter();
    this.initFetchAdvFilter();
  },
  watch: {
    "$route.query": function (newVal, oldVal) {
      const newQuery = _.reduce(
        newVal,
        function (result, value, key) {
          return _.isEqual(value, oldVal[key])
            ? result
            : value
            ? result.concat(key)
            : result;
        },
        []
      ).filter((val) => val);
      if (newQuery.length === 1 && newQuery.includes("page")) return;
      this.fetchCandidates();
      if (newQuery.length === 1 && newQuery.includes("job_id"))
        this.fetchAdvancedFilters();
    },
  },
};
</script>