<template>
  <div>
    <span v-if="appliedFilters.length" class="mr-1">Selected Filters:</span>
    <div
      v-for="preScreening in appliedFilters"
      :key="preScreening.question_id"
      class="mt-2 filter-details d-inline-block"
    >
      <div class="filter-details-item d-flex vs__selected pr-0">
        <span>{{ preScreening.question }} :</span>
        <div
          v-for="(answer,index) in preScreening.answer"
          :key="index"
          class="vs__selected px-1 m-0 ml-1"
        >
          <span
            class="rtx-40"
          >
            {{ answer }}
            <button
              type="button"
              aria-label="Deselect option"
              class="vs__deselect"
              @click="removeFilter(preScreening.question_id,index)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                <path
                  d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                />
              </svg>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    appliedFilters:{
        type:Array,
        default:[],
    }
  },
  methods:{
    removeFilter(question_id,index){
      this.$emit("removeFilter",question_id,index)
    }
  }
};
</script>