<template>
  <div>
    <CandidateJobAlert :isCollapse="true" :isDefaultShow="false"/>
    <div class="col-sm-12 job-candidate-status border-light">
      <h5 class="text-primary text-center mt-3">
        Job and Candidate Status Overview
      </h5>
      <CTabs :activeTab="activeTab" @update:activeTab="changeActiveTab">
        <CTab title="Home">
          <BasicFilter
            v-if="activeTab === 0 && !isFetchingFilter"
            @setJobCandidates="setJobCandidates"
          />
        </CTab>
        <CTab title="Refine">
          <AdvancedFilter
            v-if="activeTab === 1 && !isFetchingFilter"
            @setJobCandidates="setJobCandidates"
          />
        </CTab>
      </CTabs>
      <hr class="mb-4" />
      <CRow class="mb-3">
        <CCol v-for="(item, index) in jobCandidates" :key="index" sm="6" md="3">
          <div v-if="isFetchingJobCandidateSts" class="skeleton-card"></div>
          <candidate-info-card v-else :item="item" />
        </CCol>
        <CCol
          class="d-flex justify-content-center p-4"
          v-if="
            !jobCandidates.length &&
            !isFetchingJobCandidateSts &&
            !isFetchingJobCandidateStsFilter
          "
        >
          <h1>No Data found!</h1>
        </CCol>
      </CRow>
      <!-- <CRow
        class="d-flex justify-content-around align-items-center"
        style="height: 200px"
        v-if="isFetchingJobCandidateSts"
      >
        <CCol>
          <div class="has-loading-overlay">
            <LoadingOverlay />
          </div>
        </CCol>
      </CRow> -->
    </div>
  </div>
</template>

<script>
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
import { mapActions, mapGetters } from "vuex";
import CandidateInfoCard from "@/containers/JobCandidateStatus/CandidateInfoCard.vue";
import CandidateJobAlert from "@/containers/Dashboard/RecruiterDashBoard/Alert1-CandidateJobStatus";
import BasicFilter from "@/pages/JobCandidatesStatus/BasicFilter";
import AdvancedFilter from "@/pages/JobCandidatesStatus/AdvancedFilter";

export default {
  components: {
    BasicFilter,
    AdvancedFilter,
    CandidateInfoCard,
    CandidateJobAlert,
  },
  name: "JobCandidatesStatus",
  data: () => ({
    activeTab: null,
    jobCandidates: [],
  }),
  computed: {
    ...mapGetters([
      "isFetchingFilter",
      "isFetchingJobCandidateSts",
      "isFetchingJobCandidateStsFilter",
      "getJobCandidatesByStatusID",
      "preScreeningQuestions",
    ]),
  },
  methods: {
    ...mapActions([
      "initJobCandidatesStatusFetchOptions",
      "setCurrentNavigationCandidatesPage",
      "resetJobCandidateStatus",
      "scrollTop",
    ]),
    changeActiveTab(tabIndex) {
      this.activeTab = tabIndex;
      let queryPayload = {};
      queryPayload = { ...queryPayload, tabIndex };
      if (tabIndex === 0) queryPayload = { ...queryPayload, tab: "allTab" };
      else queryPayload = { ...queryPayload, tab: 1, tabFilter: 1 };
      this.$router.push(
        `/job-candidates-status?${getFilterQueryStringWithoutArray(
          queryPayload
        )}`
      );
    },
    setJobCandidates(jobCandidates) {
      this.jobCandidates = jobCandidates || [];
    },
  },
  mounted() {
    this.scrollTop();
    this.resetJobCandidateStatus();
    this.initJobCandidatesStatusFetchOptions();
    //For setting getNextCandidateId and getPreviousCandidateId function according to current Page
    let page = {
      currentPage: this.$router.currentRoute.name,
    };
    this.setCurrentNavigationCandidatesPage(page);
    this.activeTab = parseInt(this.$router.currentRoute?.query?.tabIndex || 0);
  },
};
</script>

<style lang="scss" scoped>
.job-candidate-status {
  background-color: white;
  margin: auto;
  min-height: 325px;
}
.filterheight {
  height: 80px;
}
@media all and (max-width: 480px) {
  .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filterheight {
    flex: 0 0 98.33333%;
    max-width: 98.33333%;
  }
  .v-select {
    width: 250px;
  }
}
@media all and (max-width: 768px) {
  .hidden-label {
    display: none;
  }
}
</style>
